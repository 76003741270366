<template>
  <div>
    <navbar />
    <side-bar />
    <v-row>
      <v-col col="12" sm="1"></v-col>
      <v-col col="12" sm="3">
        <v-select v-model="matrice_selected" :items="matrice_id_name" label="Select Matrice" chips persistent-hint
          @change="rechargeData"></v-select>
      </v-col>
      <v-col col="12" sm="1"></v-col>
      <v-col col="12" sm="3">
        <v-select v-if="this.tag_available.length > 0" v-model="tag_selected" :items="tag_name_available" multiple
          label="Select Tag" chips persistent-hint></v-select>
      </v-col>
      <v-col col="12" sm="1"></v-col>
      <v-col col="12" sm="1">
        <v-btn v-if="show == false" color="blue" style="margin-top: 20px" @click="ValidateDataSelected()">Go</v-btn>
      </v-col>
      <v-col col="12" sm="1">
        <v-btn v-if="show == true" @click="rechargeData(), show = false" style=" margin-top: 20px;">
          <v-icon>mdi-sync</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" sm="1"></v-col>
      <v-col col="12" sm="7">
        <div v-if="vibration[0] || environnement[0] || ammoniaque[0]" class="row-between">
          <div v-if="environnement[0]" class="env-box row-around" id="box-temperature">
            <img src="@/assets/ico/température_matrice_2D.webp" alt="">
            <div class="width-box">
              <h5>{{ environnement[0].température }}°C</h5>
              <p>Température</p>
            </div>
            <div class="name-capteur">
              <button id="button-see-curves" class="row-between"
                @click.stop="openCurveEnvDialog(environnement[0])"><v-icon>mdi-chart-bell-curve</v-icon>
                <p> Voir Courbes </p>
              </button>
            </div>
          </div>
          <div v-if="environnement[0]" class="env-box row-around" id="box-luminosité">
            <img src="@/assets/ico/luminosité_matrice_2D.webp" alt="">
            <div class="width-box">
              <h5>{{ environnement[0].luminosité }} lux</h5>
              <p>Luminosité</p>
            </div>
            <div class="name-capteur">
              <button id="button-see-curves" class="row-between"
                @click.stop="openCurveEnvDialog(environnement[0])"><v-icon>mdi-chart-bell-curve</v-icon>
                <p> Voir Courbes </p>
              </button>
            </div>
          </div>
          <div v-if="environnement[0]" class="env-box row-around" id="box-humidité">
            <img src="@/assets/ico/humidité_matrice_2D.webp" alt="">
            <div class="width-box">
              <h5>{{ environnement[0].humidité }} %</h5>
              <p>Humidité</p>
            </div>
            <div class="name-capteur">
              <button id="button-see-curves" class="row-between"
                @click.stop="openCurveEnvDialog(environnement[0])"><v-icon>mdi-chart-bell-curve</v-icon>
                <p> Voir Courbes </p>
              </button>
            </div>
          </div>
          <div v-if="vibration[0]" class="env-box row-around" id="box-vibration">
            <img src="@/assets/ico/activité_matrice_2D.webp" alt="">
            <div class="width-box">
              <h5>{{ vibration[0].activité }}</h5>
              <p>Activité groupe</p>
            </div>
            <div class="name-capteur">
              <button id="button-see-curves" class="row-between"
                @click.stop="openCurveVibDialog(vibration[0])"><v-icon>mdi-chart-bell-curve</v-icon>
                <p> Voir Courbes </p>
              </button>
            </div>
          </div>
          <div v-if="ammoniaque[0]" class="env-box row-around" id="box-ammoniaque">
            <img src="@/assets/illustrations/icône_ammoniac_matrice_2D.webp" alt="">
            <div class="width-box">
              <h5>{{ ammoniaque[0].ammoniaque }}</h5>
              <p>Ammoniaque</p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col col="12" sm="1"></v-col>
    </v-row>
    <v-row>
      <v-col col="12" sm="1"></v-col>
      <v-col col="12" sm="7">
        <v-container class="container-matrice">
          <v-row class="lign" no-gutters>
            <v-col v-for="n in 8" :key="n">
              <v-card class="card" tile outlined>
                <h2 id="numero">{{ n }}</h2>
                <!-- Boucle sur chaque souris -->
                <div v-for="(souris, index) in souris" :key="souris.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="souris.position === n" :id="'legende' + index" class="souris-cercle"></div>
                  <div v-if="souris.positionMemo === n" :id="'legende' + index" class="souris-cercle-memo"></div>
                </div>
                <div v-for="(vibration, index) in vibration" :key="vibration.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="vibration.position === n" :id="'legendevibration' + index" class="capteur-environnement">
                  </div>
                </div>
                <div v-for="(environnement, index) in environnement" :key="environnement.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="environnement.position === n" :id="'legendeenvironnement' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(ammoniaque, index) in ammoniaque" :key="ammoniaque.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="ammoniaque.position === n" :id="'legendeammoniaque' + index" class="capteur-environnement">
                  </div>
                </div>
                <div v-for="(sphere, index) in sphere" :key="sphere.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="sphere.position === n" :id="'legendesphere' + index" class="capteur-sphere"></div>
                </div>
                <div v-for="point in points" :key="`point-${point.caseIndex}-${point.x}-${point.y}`"
                  v-if="point.caseIndex === n" class="point"
                  :style="{ left: `${point.x}%`, top: `${point.y}%`, backgroundColor: point.couleur }"></div>
                <div v-for="points_sphere in points_sphere"
                  :key="`point-${points_sphere.caseIndex}-${points_sphere.x}-${points_sphere.y}`"
                  v-if="points_sphere.caseIndex === n" class="point"
                  :style="{ left: `${points_sphere.x}%`, top: `${points_sphere.y}%`, backgroundColor: points_sphere.couleur }">
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="lign" no-gutters>
            <v-col v-for="n in 8" :key="n">
              <v-card class="card" tile outlined>
                <h2 id="numero">{{ 8 + n }}</h2>
                <!-- Boucle sur chaque souris -->
                <div v-for="(souris, index) in souris" :key="souris.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="souris.position === 8 + n" :id="'legende' + index" class="souris-cercle"></div>
                  <div v-if="souris.positionMemo === 8 + n" :id="'legende' + index" class="souris-cercle-memo"></div>
                </div>
                <div v-for="(vibration, index) in vibration" :key="vibration.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="vibration.position === 8 + n" :id="'legendevibration' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(environnement, index) in environnement" :key="environnement.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="environnement.position === 8 + n" :id="'legendeenvironnement' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(ammoniaque, index) in ammoniaque" :key="ammoniaque.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="ammoniaque.position === 8 + n" :id="'legendeammoniaque' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(sphere, index) in sphere" :key="sphere.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="sphere.position === 8 + n" :id="'legendesphere' + index" class="capteur-sphere"></div>
                </div>
                <div v-for="point in points" :key="`point-${point.caseIndex}-${point.x}-${point.y}`"
                  v-if="point.caseIndex === 8 + n" class="point"
                  :style="{ left: `${point.x}%`, top: `${point.y}%`, backgroundColor: point.couleur }"></div>
                <div v-for="points_sphere in points_sphere"
                  :key="`point-${points_sphere.caseIndex}-${points_sphere.x}-${points_sphere.y}`"
                  v-if="points_sphere.caseIndex === 8 + n" class="point"
                  :style="{ left: `${points_sphere.x}%`, top: `${points_sphere.y}%`, backgroundColor: points_sphere.couleur }">
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="lign" no-gutters>
            <v-col v-for="n in 8" :key="n">
              <v-card class="card" tile outlined>
                <h2 id="numero">{{ 16 + n }}</h2>
                <!-- Boucle sur chaque souris -->
                <div v-for="(souris, index) in souris" :key="souris.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="souris.position === 16 + n" :id="'legende' + index" class="souris-cercle"></div>
                  <div v-if="souris.positionMemo === 16 + n" :id="'legende' + index" class="souris-cercle-memo"></div>
                </div>
                <div v-for="(vibration, index) in vibration" :key="vibration.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="vibration.position === 16 + n" :id="'legendevibration' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(environnement, index) in environnement" :key="environnement.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="environnement.position === 16 + n" :id="'legendeenvironnement' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(ammoniaque, index) in ammoniaque" :key="ammoniaque.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="ammoniaque.position === 16 + n" :id="'legendeammoniaque' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(sphere, index) in sphere" :key="sphere.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="sphere.position === 16 + n" :id="'legendesphere' + index" class="capteur-sphere"></div>
                </div>
                <div v-for="point in points" :key="`point-${point.caseIndex}-${point.x}-${point.y}`"
                  v-if="point.caseIndex === 16 + n" class="point"
                  :style="{ left: `${point.x}%`, top: `${point.y}%`, backgroundColor: point.couleur }"></div>
                <div v-for="points_sphere in points_sphere"
                  :key="`point-${points_sphere.caseIndex}-${points_sphere.x}-${points_sphere.y}`"
                  v-if="points_sphere.caseIndex === 16 + n" class="point"
                  :style="{ left: `${points_sphere.x}%`, top: `${points_sphere.y}%`, backgroundColor: points_sphere.couleur }">
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="lign" no-gutters>
            <v-col v-for="n in 8" :key="n">
              <v-card class="card" tile outlined>
                <h2 id="numero">{{ 24 + n }}</h2>
                <!-- Boucle sur chaque souris -->
                <div v-for="(souris, index) in souris" :key="souris.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="souris.position === 24 + n" :id="'legende' + index" class="souris-cercle"></div>
                  <div v-if="souris.positionMemo === 24 + n" :id="'legende' + index" class="souris-cercle-memo"></div>
                </div>
                <div v-for="(vibration, index) in vibration" :key="vibration.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="vibration.position === 24 + n" :id="'legendevibration' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(environnement, index) in environnement" :key="environnement.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="environnement.position === 24 + n" :id="'legendeenvironnement' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(ammoniaque, index) in ammoniaque" :key="ammoniaque.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="ammoniaque.position === 24 + n" :id="'legendeammoniaque' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(sphere, index) in sphere" :key="sphere.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="sphere.position === 24 + n" :id="'legendesphere' + index" class="capteur-sphere"></div>
                </div>
                <div v-for="point in points" :key="`point-${point.caseIndex}-${point.x}-${point.y}`"
                  v-if="point.caseIndex === 24 + n" class="point"
                  :style="{ left: `${point.x}%`, top: `${point.y}%`, backgroundColor: point.couleur }"></div>
                <div v-for="points_sphere in points_sphere"
                  :key="`point-${points_sphere.caseIndex}-${points_sphere.x}-${points_sphere.y}`"
                  v-if="points_sphere.caseIndex === 24 + n" class="point"
                  :style="{ left: `${points_sphere.x}%`, top: `${points_sphere.y}%`, backgroundColor: points_sphere.couleur }">
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="lign" no-gutters>
            <v-col v-for="n in 8" :key="n">
              <v-card class="card" tile outlined>
                <h2 id="numero">{{ 32 + n }}</h2>
                <!-- Boucle sur chaque souris -->
                <div v-for="(souris, index) in souris" :key="souris.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="souris.position === 32 + n" :id="'legende' + index" class="souris-cercle"></div>
                  <div v-if="souris.positionMemo === 32 + n" :id="'legende' + index" class="souris-cercle-memo"></div>
                </div>
                <div v-for="(vibration, index) in vibration" :key="vibration.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="vibration.position === 32 + n" :id="'legendevibration' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(environnement, index) in environnement" :key="environnement.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="environnement.position === 32 + n" :id="'legendeenvironnement' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(ammoniaque, index) in ammoniaque" :key="ammoniaque.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="ammoniaque.position === 32 + n" :id="'legendeammoniaque' + index"
                    class="capteur-environnement"></div>
                </div>
                <div v-for="(sphere, index) in sphere" :key="sphere.nom">
                  <!-- Vérifie si la position de la souris correspond au numéro de la case -->
                  <div v-if="sphere.position === 32 + n" :id="'legendesphere' + index" class="capteur-sphere"></div>
                </div>
                <div v-for="point in points" :key="`point-${point.caseIndex}-${point.x}-${point.y}`"
                  v-if="point.caseIndex === 32 + n" class="point"
                  :style="{ left: `${point.x}%`, top: `${point.y}%`, backgroundColor: point.couleur }"></div>
                <div v-for="points_sphere in points_sphere"
                  :key="`point-${points_sphere.caseIndex}-${points_sphere.x}-${points_sphere.y}`"
                  v-if="points_sphere.caseIndex === 32 + n" class="point"
                  :style="{ left: `${points_sphere.x}%`, top: `${points_sphere.y}%`, backgroundColor: points_sphere.couleur }">
                </div>
              </v-card>
            </v-col>
          </v-row>

        </v-container>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="flex-item" v-if="matrice_selected">
          <div class="souris-box" id="legendecoloropacity4">
            <v-card-title>
              {{ matrice_selected }}
            </v-card-title>
          </div>
        </div>
        <div v-for="(souris, index) in souris" :key="souris.surnom" class="flex-item ">
          <div class="souris-box" :id="'legendecoloropacity' + index">
            <div></div>
            <v-card-title @click="toggle(souris.surnom)" class="row-between">
              <div class="row-between">
                {{ souris.surnom }}
                <div :id="'legende' + index" class="souris-legende"></div>
              </div>
              <button v-if="expandedStates[souris.surnom]" id="button-see-curves" class="row-between"
                @click.stop="openCurveDialog(souris)"><v-icon>mdi-chart-bell-curve</v-icon>
                <p> Voir Courbes </p>
              </button>
            </v-card-title>
            <v-expand-transition>
              <div v-if="expandedStates[souris.surnom]">
                <div class="row-between info-box-souris">
                  <div>
                    <div class="row-between">
                      <h5>{{ souris.température }} </h5>
                      <p id="signe">°C</p>
                    </div>
                    <p id="data">température</p>
                  </div>
                  <div>
                    <div :id="'legendeétatméta' + souris.illustration_activité" class="souris-legende-état" alt="">
                    </div>
                    <p>état : {{ souris.activité }}</p>
                  </div>
                </div>
                <div class="row-between info-box-souris">
                  <div>
                    <div class="row-between">
                      <h5>{{ souris.distance_totale }} </h5>
                      <p id="signe">m</p>
                    </div>
                    <p id="data">distance parcourue</p>
                  </div>
                  <div>
                    <div class="row-between">
                      <h5>{{ souris.position }} </h5>
                    </div>
                    <p id="data">position</p>
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </div>
      </v-col>
    </v-row>
    <br>
    <br><br>
    <v-dialog v-model="loading" width="500">
      <v-card>
        <v-card-text style="text-align: center;">Recherche de matériels présents sur la matrice : {{ matrice_selected2
          }}</v-card-text>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="1200">
      <accxyzvib v-if="dialog" ref="accTagRef" v-bind:tag_id="tag_id_temp" :tag_id_name="tag_id_temp_name" />
      <temp_tag v-if="dialog" ref="tempTagRef" v-bind:tag_id="tag_id_temp" :tag_id_name="tag_id_temp_name" />
    </v-dialog>
    <v-dialog v-model="dialogEnv" width="1200">
      <v-row>
        <v-col cols="12" sm="6">
          <humidite v-if="dialogEnv" ref="humiTagRef" v-bind:tag_id="tag_id_lumi" :tag_id_name="tag_id_lumi_name" />
        </v-col>
        <v-col cols="12" sm="6">
          <luminosite v-if="dialogEnv" ref="lumiTagRef" v-bind:tag_id="tag_id_lumi" :tag_id_name="tag_id_lumi_name" />
        </v-col>
      </v-row>
      <temp_tag v-if="dialogEnv" ref="tempTagRef" v-bind:tag_id="tag_id_lumi" :tag_id_name="tag_id_lumi_name" />
    </v-dialog>
    <v-dialog v-model="dialogVib" width="1200">
      <accxyzvib v-if="dialogVib" ref="accTagRef" v-bind:tag_id="tag_id_acc" :tag_id_name="tag_id_acc_name" />
    </v-dialog>
  </div>
</template>




<script>
import { switchToken } from '../../functions/test-token';
import navbar from '@/components/NavBarCloud.vue';
import SideBar from '@/components/SideBar'
import temp_tag from '../../components/cloud/PageLiveData/courbe-temp-tag.vue';
import accxyzvib from '../../components/cloud/PageLiveData/acc-xyz-vib.vue';
import luminosite from '../../components/cloud/PageLiveData/courbe-lumi-tag.vue';
import humidite from '../../components/cloud/PageLiveData/courbe-humi-tag.vue';

export default {
  props: ["tag_id"],
  components: {
    SideBar,
    navbar,
    temp_tag,
    accxyzvib,
    luminosite,
    humidite,
  },
  data() {
    return {
      Show: false,
      matrice_id: [],
      matrice_id_name: [],
      matrice_selected: null,
      tag_selected: [],
      tag_selected_real: [],
      tag_available: [],
      tag_name_available: [],
      implant: [],
      implant_name: [],
      capteur_amonniaque: [],
      capteur_amonniaque_name: [],
      capteur_vibration: [],
      capteur_vibration_name: [],
      capteur_sphere: [],
      capteur_sphere_name: [],
      capteur_environnement: [],
      capteur_environnement_name: [],
      nIntervId: [],
      nIntervIdSouris: [],
      tagSouris: [],
      nIntervIdAmq: [],
      capteurAmmoniaqueSelected: [],
      nIntervIdEnv: [],
      capteurEnvironnementSelected: [],
      nIntervIdVib: [],
      capteurVibrationSelected: [],
      nIntervIdSph: [],
      capteurSphereSelected: [],
      show: false,
      show_temp: false,
      expanded: null,
      dialog: false,
      dialogEnv: false,
      dialogVib: false,
      componentKey: 0,
      souris: [],
      ammoniaque: [],
      vibration: [],
      sphere: [],
      environnement: [],
      souris_couleurs: [
        'rgb(0, 188, 212)',
        'rgb(255, 153, 102)',
        'rgb(150, 123, 200)',
        'rgb(57, 180, 63)'],
      sphere_couleurs: [
        'rgb(206, 45, 80)',
        'rgb(206, 45, 134)',
        'rgb(206, 45, 198)'
      ],
      matrice_selected2: '',
      loading: false,
      expandedStates: {},
      points: [],
      points_sphere: [],
      tag_id_temp: [],
      tag_id_temp_name: [],
      tag_id_lumi: [],
      tag_id_lumi_name: [],
      tag_id_acc: [],
      tag_id_acc_name: [],
    }
  },
  async mounted() {
    await this.getMatriceAvailable();
    await this.GetTags();
  },
  created() {
    // Initialisation de l'état d'expansion pour chaque type de tags
    this.souris.forEach(souris => {
      this.$set(this.expandedStates, souris.nom, false);
    });
    this.vibration.forEach(vibration => {
      this.$set(this.expandedStates, vibration.nom, false);
    });
    this.environnement.forEach(environnement => {
      this.$set(this.expandedStates, environnement.nom, false);
    });
    this.ammoniaque.forEach(ammoniaque => {
      this.$set(this.expandedStates, ammoniaque.nom, false);
    });
  },
  methods: {
    async toggle(sourisNom) {
      // Utiliser this.$set pour s'assurer que la modification est réactive
      this.$set(this.expandedStates, sourisNom, !this.expandedStates[sourisNom]);
    },
    async ValidateDataSelected() {
      this.tag_selected_real = []
      for (let i = 0; i < this.tag_selected.length; i++) {
        this.tag_selected_real.push(this.tag_available[this.tag_name_available.indexOf(this.tag_selected[i])])
      }

      for (let i = 0; i < this.tag_selected_real.length; i++) {

        if (this.tag_selected_real[i].startsWith('imp_')) {
          this.tagSouris.push(this.tag_selected_real[i])
        }

        if (this.tag_selected_real[i].startsWith('amq_')) {
          this.capteurAmmoniaqueSelected.push(this.tag_selected_real[i])
        }

        if (this.tag_selected_real[i].startsWith('env_')) {
          this.capteurEnvironnementSelected.push(this.tag_selected_real[i])
        }

        if (this.tag_selected_real[i].startsWith('vib_') || this.tag_selected_real[i].startsWith('vib2_')) {
          this.capteurVibrationSelected.push(this.tag_selected_real[i])
        }
        if (this.tag_selected_real[i].startsWith('sph_') || this.tag_selected_real[i].startsWith('spha_')) {
          this.capteurSphereSelected.push(this.tag_selected_real[i])
        }
      }

      for (let i = 0; i < this.capteurAmmoniaqueSelected.length; i++) {
        this.ammoniaque.push({
          nom: null,
          surnom: null,
          ammoniaque: 0,
          position: 0,
          id: 0,
        })
        this.ammoniaque[i].nom = this.capteurAmmoniaqueSelected[i]
        if (this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.capteurAmmoniaqueSelected[i])] == 'noname') {
          this.ammoniaque[i].surnom = this.capteurAmmoniaqueSelected[i];
        } else {
          this.ammoniaque[i].surnom = this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.capteurAmmoniaqueSelected[i])]
        }
        this.nIntervIdAmq[i] = setInterval(() => this.getDataAmq(i), 1000)
      }

      for (let i = 0; i < this.capteurEnvironnementSelected.length; i++) {
        this.environnement.push({
          nom: null,
          surnom: null,
          température: 0,
          luminosité: 0,
          humidité: 0,
          position: 0,
          id: 0,
        })
        this.environnement[i].nom = this.capteurEnvironnementSelected[i]
        if (this.capteur_environnement_name[this.capteur_environnement.indexOf(this.capteurEnvironnementSelected[i])] == 'noname') {
          this.environnement[i].surnom = this.capteurEnvironnementSelected[i];
        } else {
          this.environnement[i].surnom = this.capteur_environnement_name[this.capteur_environnement.indexOf(this.capteurEnvironnementSelected[i])]
        }
        this.nIntervIdEnv[i] = setInterval(() => this.getDataEnv(i), 1000)
      }
      for (let i = 0; i < this.capteurVibrationSelected.length; i++) {
        this.vibration.push({
          nom: null,
          surnom: null,
          activité: 0,
          position: 0,
          id: 0,
        })
        this.vibration[i].nom = this.capteurVibrationSelected[i]
        if (this.capteur_vibration_name[this.capteur_vibration.indexOf(this.capteurVibrationSelected[i])] == 'noname') {
          this.vibration[i].surnom = this.capteurVibrationSelected[i];
        } else {
          this.vibration[i].surnom = this.capteur_vibration_name[this.capteur_vibration.indexOf(this.capteurVibrationSelected[i])]
        }
        this.nIntervIdVib[i] = setInterval(() => this.getDataVib(i), 1000)
      };
      for (let i = 0; i < this.capteurSphereSelected.length; i++) {
        this.sphere.push({
          nom: null,
          surnom: null,
          position: 0,
          hasAcc: null,
          id: 0,
        })
        this.sphere[i].nom = this.capteurSphereSelected[i]
        console.log(this.sphere[i].nom)
        if (this.sphere[i].nom.startsWith('spha')) {
          this.sphere[i].hasAcc = true;
        } else {
          this.sphere[i].hasAcc = false;
        }
        if (this.capteur_sphere_name[this.capteur_sphere.indexOf(this.capteurSphereSelected[i])] == 'noname') {
          this.sphere[i].surnom = this.capteurSphereSelected[i];
        } else {
          this.sphere[i].surnom = this.capteur_sphere_name[this.capteur_sphere.indexOf(this.capteurSphereSelected[i])]
        }
        this.sphere[i].couleur = this.sphere_couleurs[i]
        this.nIntervIdSph[i] = setInterval(() => this.getDataSphere(i), 1000)
      }
      for (let i = 0; i < this.tagSouris.length; i++) {
        this.nIntervIdSouris[i] = setInterval(() => this.getDataSouris(i), 250)
        this.souris.push({
          nom: null,
          surnom: null,
          température: 0,
          position: 0,
          position_mémo_x: 0,
          position_mémo_y: 0,
          distance_totale: 0,
          positionMemo: 0,
          activité: '',
          illustration_activité: 0,
          couleur: '',
          id: 0,
        })
        this.souris[i].nom = this.tagSouris[i]
        if (this.implant_name[this.implant.indexOf(this.tagSouris[i])] == 'noname') {
          this.souris[i].surnom = this.tagSouris[i];
        } else {
          this.souris[i].surnom = this.implant_name[this.implant.indexOf(this.tagSouris[i])]
        }
        this.souris[i].couleur = this.souris_couleurs[i]
      }
      this.show = true;
    },
    async GetTags() {
      const user = JSON.parse(localStorage.getItem('user'))
      const accessToken = localStorage.getItem('accessToken');
      const email = user.email
      const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      });
      return fetch(url, { headers: headers })
        .then(res => {
          if (res.status === 401) {
            switchToken().then(() => {
              // Refaites la requête avec le nouveau token
              this.GetTags();
            });
          }
          return res.text();
        })
        .then(async (result) => {
          const data = JSON.parse(result);
          this.implant = data.tag_attributed
          this.implant_name = data.tag_renamed
          this.capteur_amonniaque = data.capteur_amonniaque;
          this.capteur_amonniaque_name = data.amonniaque_renamed;
          this.capteur_vibration = data.capteur_vibration;
          this.capteur_vibration_name = data.vibration_renamed;
          this.capteur_sphere = data.capteur_sphere;
          this.capteur_sphere_name = data.sphere_renamed;
          this.capteur_environnement = data.capteur_environnement;
          this.capteur_environnement_name = data.environnement_renamed;
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getTagAvailable() {
      let id_matrice;
      id_matrice = this.matrice_id_name.indexOf(this.matrice_selected)
      const matrice = encodeURIComponent(this.matrice_id[id_matrice])
      const url = this.$api.getRESTApiUri() + `/real-time/check-tag-available/${matrice}`
      const accessToken = localStorage.getItem('accessToken');
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      });
      return fetch(url, { headers: headers })
        .then(res => {
          if (res.status === 401) {
            switchToken().then(() => {
              // Refaites la requête avec le nouveau token
              this.getTagAvailable();
            });
          }
          return res.text();
        })
        .then((result) => {
          const data = JSON.parse(result);
          this.tag_available = data.tag_available.filter(tag => {
            // Vérifier si le tag est présent dans au moins un des tableaux cibles
            return (
              this.capteur_amonniaque.includes(tag) ||
              this.capteur_vibration.includes(tag) ||
              this.capteur_sphere.includes(tag) ||
              this.capteur_environnement.includes(tag) ||
              this.implant.includes(tag)
            );
          });

          this.tag_name_available = [];
          for (let i = 0; i < this.tag_available.length; i++) {
            if (this.tag_available[i].startsWith('imp_')) {
              if (this.implant_name[this.implant.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.implant_name[this.implant.indexOf(this.tag_available[i])])
              }
            } else if (this.tag_available[i].startsWith('ppg1')) {
              if (this.implant_name[this.implant.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.implant_name[this.implant.indexOf(this.tag_available[i])])
              }
            } else if (this.tag_available[i].startsWith('env_')) {
              if (this.capteur_environnement_name[this.capteur_environnement.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.capteur_environnement_name[this.capteur_environnement.indexOf(this.tag_available[i])])
              }
            } else if (this.tag_available[i].startsWith('vib_')) {
              if (this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])])
              }
            } else if (this.tag_available[i].startsWith('vib2_')) {
              if (this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])])
              }
            } else if (this.tag_available[i].startsWith('sph_') || this.tag_available[i].startsWith('spha_')) {
              if (this.capteur_sphere_name[this.capteur_sphere.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.capteur_sphere_name[this.capteur_sphere.indexOf(this.tag_available[i])])
              }
            } else if (this.tag_available[i].startsWith('amq_')) {
              if (this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.tag_available[i])] == "noname") {
                this.tag_name_available.push(this.tag_available[i])
              } else {
                this.tag_name_available.push(this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.tag_available[i])])
              }
            }
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async getMatriceAvailable() {
      const user = JSON.parse(localStorage.getItem('user'))
      const accessToken = localStorage.getItem('accessToken');
      const email = user.email
      const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      });
      return fetch(url, { headers: headers })
        .then(res => {
          if (res.status === 401) {
            switchToken().then(() => {
              // Refaites la requête avec le nouveau token
              this.getMatriceAvailable();
            });
          }
          return res.text();
        })
        .then(async (result) => {
          const data = JSON.parse(result);
          const matrice_id2 = []
          this.matrice_id = data.matrice_attributed
          this.matrice_name = data.matrice_renamed
          for (let i = 0; i < this.matrice_id.length; i++) {
            if (this.matrice_id[i].startsWith('mat_')) {
              matrice_id2.push(this.matrice_id[i])
            }
          }
          this.matrice_id = matrice_id2;
          if (this.matrice_id && this.matrice_id.length > 0) {
            for (let i = 0; i < this.matrice_id.length; i++) {
              if (this.matrice_name[i] == "noname") {
                this.matrice_id_name.push(this.matrice_id[i])
              } else {
                this.matrice_id_name.push(this.matrice_name[i])
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    async rechargeData() {
      this.stopInterval();
      this.resetArrays();
      this.matrice_selected2 = this.matrice_selected;
      if (this.matrice_selected2 == '') {
        this.matrice_selected2 = 'pas de matrice sélectionnée'
      }
      this.loading = true;
      if (this.matrice_selected !== '') {
        let id_matrice;
        id_matrice = this.matrice_id_name.indexOf(this.matrice_selected)
        const matrice = encodeURIComponent(this.matrice_id[id_matrice])
        const url = this.$api.getRESTApiUri() + `/reload/tag_available/`
        await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            matrice: matrice,
          }),
        })
      }
      setTimeout(async () => {
        this.loading = false; // Arrêtez le chargement après 5 secondes
        await this.getTagAvailable();
      }, 5000);
    },
    async getDataSouris(index) {
      if (this.souris[index].position != this.souris[index].positionMemo) {
        this.souris[index].positionMemo = this.souris[index].position
      }
      const tag_id = encodeURIComponent(this.tagSouris[index]);
      const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id}`;
      return fetch(url)
        .then(res => res.text())
        .then(async (result) => {
          const data = JSON.parse(result);
          if (data[0].id <= this.souris[index].id) {
            return;
          } else {
            let activité;
            activité = data[0]?.activité
            this.souris[index].position = data[0]?.pos_y_tag * 8 + data[0]?.pos_x_tag + 1;
            this.souris[index].distance_totale = data[0]?.distance_parcourue / 100;
            this.souris[index].température = (data[0]?.temp_tag / 100).toFixed(2);
            if (this.capteurEnvironnementSelected.length) {
              const diff_temp = Math.abs(this.environnement[0].température - this.souris[index].température);
              if (diff_temp < 4 && this.souris[index].température < 36 && activité == 'Repos') {
                activité = 'Morte'
              }
            }
            this.souris[index].activité = activité;
            this.souris[index].illustration_activité = this.determineLogoActivity(activité)
            this.addPoint(this.souris[index].position, this.souris[index].couleur)

          }
          this.souris[index].id = data[0].id
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async getDataAmq(index) {
      const tag_id = encodeURIComponent(this.capteurAmmoniaqueSelected[index]);
      const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id}`;
      return fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          if (data[0].id <= this.capteurAmmoniaqueSelected[index].id) {
            return;
          } else {
            this.ammoniaque[index].ammoniaque = data[0]?.amonniac.toFixed(1);
            this.ammoniaque[index].position = data[0]?.pos_y_tag * 8 + data[0]?.pos_x_tag + 1;
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async getDataEnv(index) {
      const tag_id = encodeURIComponent(this.capteurEnvironnementSelected[index]);
      const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id}`;
      return fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          if (data[0].id <= this.capteurEnvironnementSelected[index].id) {
            return;
          } else {
            this.environnement[index].température = data[0]?.temp_tag / 100;
            this.environnement[index].luminosité = data[0]?.tag_brightness.toFixed(1);
            this.environnement[index].humidité = data[0]?.tag_humidity.toFixed(1);
            this.environnement[index].position = data[0]?.pos_y_tag * 8 + data[0]?.pos_x_tag + 1;
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async getDataVib(index) {
      const tag_id = encodeURIComponent(this.capteurVibrationSelected[index]);
      const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id}`;
      return fetch(url)
        .then(res => res.text())
        .then(async (result) => {
          const data = JSON.parse(result);
          if (data[0].id <= this.vibration[index].id) {
            return;
          } else {
            this.vibration[index].activité = await this.determineCageActivity(data[0]);
            this.vibration[index].position = data[0]?.pos_y_tag * 8 + data[0]?.pos_x_tag + 1;
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async getDataSphere(index) {
      const tag_id = encodeURIComponent(this.capteurSphereSelected[index]);
      const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id}`;
      return fetch(url)
        .then(res => res.text())
        .then(async (result) => {
          const data = JSON.parse(result);
          if (data[0].id <= this.sphere[index].id) {
            return;
          } else {
            this.sphere[index].position = data[0]?.pos_y_tag * 8 + data[0]?.pos_x_tag + 1;
            this.addPointSphere(this.sphere[index].position, this.sphere[index].couleur)
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async determineCageActivity(data) {
      // Seuils d'activité pour chaque axe
      const ACTIVITY_THRESHOLD_X = 0.1; // Ajustez ce seuil selon vos données
      const ACTIVITY_THRESHOLD_Y = 0.1; // Ajustez ce seuil selon vos données
      const ACTIVITY_THRESHOLD_Z = 0.1; // Ajustez ce seuil selon vos données
      const EXCITEMENT_THRESHOLD = 0.35; // Seuil pour déterminer l'état "excité"

      // Calcul des amplitudes pour chaque axe
      const amplitudeX = Math.max(...data.acc_x_tag) - Math.min(...data.acc_x_tag);
      const amplitudeY = Math.max(...data.acc_y_tag) - Math.min(...data.acc_y_tag);
      const amplitudeZ = Math.max(...data.acc_z_tag) - Math.min(...data.acc_z_tag);

      // Détermination de l'état "excité"
      const isExcited = amplitudeX > EXCITEMENT_THRESHOLD || amplitudeY > EXCITEMENT_THRESHOLD || amplitudeZ > EXCITEMENT_THRESHOLD;

      // Si la souris est "excitée", retourner immédiatement cet état
      if (isExcited) {
        return 'Forte';
      }

      // Sinon, déterminer si la souris est "active" ou "au repos"
      const isActiveX = amplitudeX > ACTIVITY_THRESHOLD_X;
      const isActiveY = amplitudeY > ACTIVITY_THRESHOLD_Y;
      const isActiveZ = amplitudeZ > ACTIVITY_THRESHOLD_Z;

      if (isActiveX || isActiveY || isActiveZ) {
        return 'Moyenne';
      } else {
        return 'Faible';;
      }
    },
    openCurveDialog(souris) {
      this.tag_id_temp = [];
      this.tag_id_temp_name = [];
      this.$set(this.tag_id_temp, this.tag_id_temp.length, souris.nom);
      this.$set(this.tag_id_temp_name, this.tag_id_temp_name.length, souris.surnom);
      this.componentKey++;
      this.dialog = true;
      this.$nextTick(() => {
        if (this.$refs.tempTagRef) {
          this.$refs.tempTagRef.forceChartRerender();
        }
      });
      this.$nextTick(() => {
        if (this.$refs.accTagRef) {
          this.$refs.accTagRef.forceChartRerender();
        }
      });
    },
    openCurveEnvDialog(environnement) {
      this.tag_id_lumi = [];
      this.tag_id_lumi_name = [];
      this.$set(this.tag_id_lumi, this.tag_id_lumi.length, environnement.nom);
      this.$set(this.tag_id_lumi_name, this.tag_id_lumi_name.length, environnement.surnom);
      this.componentKey++;
      this.dialogEnv = true;
      this.$nextTick(() => {
        if (this.$refs.tempTagRef) {
          this.$refs.tempTagRef.forceChartRerender();
        }
      });
      this.$nextTick(() => {
        if (this.$refs.lumiTagRef) {
          this.$refs.lumiTagRef.forceChartRerender();
        }
      });
      this.$nextTick(() => {
        if (this.$refs.humiTagRef) {
          console.log('heyyo ^^')
          this.$refs.humiTagRef.forceChartRerender();
        }
      });
    },
    openCurveVibDialog(vibration) {
      console.log(vibration)
      this.tag_id_acc = [];
      this.tag_id_acc_name = [];
      this.$set(this.tag_id_acc, this.tag_id_acc.length, vibration.nom);
      this.$set(this.tag_id_acc_name, this.tag_id_acc_name.length, vibration.surnom);
      this.componentKey++;
      this.dialogVib = true;
      this.$nextTick(() => {
        if (this.$refs.accTagRef) {
          this.$refs.accTagRef.forceChartRerender();
        }
      });
    },
    determineLogoActivity(activité) {
      let number_illustration;
      switch (activité) {
        case 'Active':
          number_illustration = 0;
          break;
        case 'Repos':
          number_illustration = 1;
          break
        case 'Excitée':
          number_illustration = 2;
          break
        case 'Morte':
          number_illustration = 3;
          break
      }
      return number_illustration
    },
    addPoint(caseIndex, couleurSouris) {
      const x = Math.random() * 100; // Génère un nombre aléatoire entre 0 et 100
      const y = Math.random() * 100; // Génère un nombre aléatoire entre 0 et 100
      this.points.push({ caseIndex, x, y, couleur: couleurSouris });
    },
    addPointSphere(caseIndex, couleurSphere) {
      const x = Math.random() * 100; // Génère un nombre aléatoire entre 0 et 100
      const y = Math.random() * 100; // Génère un nombre aléatoire entre 0 et 100
      this.points_sphere.push({ caseIndex, x, y, couleur: couleurSphere });
    },
    stopInterval() {
      for (let i = 0; i < this.tagSouris.length; i++) {
        clearInterval(this.nIntervIdSouris[i])
      }
      for (let i = 0; i < this.capteurVibrationSelected.length; i++) {
        clearInterval(this.nIntervIdVib[i])
      }
      for (let i = 0; i < this.capteurEnvironnementSelected.length; i++) {
        clearInterval(this.nIntervIdEnv[i])
      }
      for (let i = 0; i < this.capteurAmmoniaqueSelected.length; i++) {
        clearInterval(this.nIntervIdAmq[i])
      }
      for (let i = 0; i < this.capteurSphereSelected.length; i++) {
        clearInterval(this.nIntervIdSph[i])
      }
    },
    resetArrays() {
      this.tag_available = [];
      this.environnement = [];
      this.points = [];
      this.vibration = [];
      this.ammoniaque = [];
      this.souris = [];
      this.tag_selected = [];
      this.tag_selected_real = [];
      this.tag_available = [];
      this.tag_name_available = [];
      this.nIntervId = [];
      this.nIntervIdSouris = [];
      this.tagSouris = [];
      this.nIntervIdAmq = [];
      this.capteurAmmoniaqueSelected = [];
      this.nIntervIdEnv = [];
      this.capteurEnvironnementSelected = [];
      this.nIntervIdVib = [];
      this.capteurSphereSelected = [];
      this.nIntervIdSph = [];
      this.capteurVibrationSelected = [];
    },
  },
  beforeDestroy() {
    this.stopInterval();
  },


}
</script>

<style scoped>
.souris-legende {
  z-index: 3;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  background-size: 100% 100%;
  margin-left: 10px;
}


#legende0 {
  background-image: url("../../assets/illustrations/souris_matrice_2D_0.webp");
}

#legende1 {
  background-image: url("../../assets/illustrations/souris_matrice_2D_1.webp");
}

#legende2 {
  background-image: url("../../assets/illustrations/souris_matrice_2D_3.webp");
}

#legende3 {
  background-image: url("../../assets/illustrations/souris_matrice_2D_2.webp");
}

#legendeétatméta0 {
  background-image: url("../../assets/illustrations/souris_active.webp");
}

#legendeétatméta1 {
  background-image: url("../../assets/illustrations/souris_repos.webp");
}

#legendeétatméta2 {
  background-image: url("../../assets/illustrations/souris_excitée.webp");
}

#legendeétatméta3 {
  background-image: url("../../assets/illustrations/souris_morte.webp");
}

#legendecolor0 {
  background: rgb(0, 188, 212);
}

#legendecolor1 {
  background: rgb(255, 153, 102);
}

#legendecolor3 {
  background: rgb(150, 123, 200);
}

#legendecolor2 {
  background: rgb(57, 180, 63);
}

#legendecoloropacity0 {
  background: rgb(0, 188, 212, 0.3);
}

#legendecoloropacity1 {
  background: rgb(255, 153, 102, 0.3);
}

#legendecoloropacity2 {
  background: rgb(150, 123, 200, 0.3);
}

#legendecoloropacity3 {
  background: rgb(57, 180, 63, 0.3);
}

#legendecoloropacity4 {
  background: rgb(355, 355, 355, 0.5);
}

#legendevibration0 {
  background-image: url("../../assets/illustrations/vibration_matrice_2D.webp");
}

#legendeenvironnement0 {
  background-image: url("../../assets/illustrations/environnement_matrice_2D.webp");
}

#legendeammoniaque0 {
  background-image: url("../../assets/illustrations/ammoniac_matrice_2D.webp");
}

#legendesphere0 {
  background-color: rgb(206, 45, 80);
}

#legendesphere1 {
  background-color: rgb(206, 45, 134);
}

#legendesphere2 {
  background-color: rgb(206, 45, 198);
}

#box-temperature {
  background: rgb(83, 203, 242, 0.3);
}

#box-luminosité {
  background: rgb(249, 187, 92, 0.3);
}

#box-humidité {
  background: rgb(2, 195, 154, 0.3);
}

#box-vibration {
  background: rgb(239, 99, 99, 0.3);
}

#box-ammoniaque {
  background: rgba(240, 236, 30, 0.3);
}



.souris-cercle {
  z-index: 3;
  width: 80px;
  /* ou la taille que vous souhaitez */
  height: 80px;
  /* ou la taille que vous souhaitez */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.souris-legende-état {
  width: 80px;
  height: 80px;
  border: 0px;
  background: none;
  color: transparent;
}

.env-box {
  width: 18%;
  border-radius: 25px;
  padding: 5px 0px 0px 0px;
  align-items: center;
}

.env-box img {
  margin-left: 5px;
  margin-top: 5px;
  height: 60px;
  width: auto;
}

.width-box {
  width: 60%;
  margin-top: 10px;
}

.env-box div h5 {
  font-family: "Roboto", Medium;
  font-weight: 600;
  font-size: 25px;
}

.env-box img {
  margin-top: 10px;
}

.env-box div p {
  font-family: "Roboto", Light Italic;
  font-weight: 300;
  font-size: 16px;
}

.name-capteur {
  display: none;
  text-align: center;
  align-items: center;
  width: 90%;

}

.env-box:hover .name-capteur {
  display: block;
  /* ou 'display: flex;' si votre mise en page le nécessite */
  transition: 0.5s;
}

.env-box:hover .width-box {
  display: none;
  /* ou 'display: flex;' si votre mise en page le nécessite */
}

.souris-box {
  border-radius: 25px;
}

.info-box-souris {
  padding: 0px 20px;
}

.info-box-souris div {
  width: 45%;
  text-align: left;
}

.info-box-souris div h5 {
  margin-top: 20px;
  font-family: "Roboto", Medium;
  font-weight: 400;
  font-size: 45px;
}

#signe {
  margin-top: 5px;
  font-family: "Roboto", Light;
  font-weight: 500;
  font-size: 25px;
}

#data {
  font-family: "Roboto", Light Italic;
  margin-top: -15px;
  font-weight: 300;
  font-size: 16px;
}

.souris-cercle-memo {
  z-index: 2;
  opacity: 0.5;
  border-radius: 50%;
  width: 80px;
  /* ou la taille que vous souhaitez */
  height: 80px;
  /* ou la taille que vous souhaitez */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vibration-carré {
  border-radius: 10%;
  width: 80px;
  /* ou la taille que vous souhaitez */
  height: 80px;
  /* ou la taille que vous souhaitez */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: polygon(50% 0%, 61% 35%, 100% 35%, 73% 59%, 82% 100%, 50% 75%, 18% 100%, 27% 59%, 0% 35%, 39% 35%);
}

.capteur-environnement {
  z-index: 2;
  border-radius: 25%;
  width: 80px;
  /* ou la taille que vous souhaitez */
  height: 80px;
  /* ou la taille que vous souhaitez */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.capteur-sphere {
  z-index: 2;
  border-radius: 100%;
  width: 80px;
  /* ou la taille que vous souhaitez */
  height: 80px;
  /* ou la taille que vous souhaitez */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#numero {
  font-size: 16px;
  opacity: 0.2;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.container-matrice {
  background-color: rgb(355, 355, 355, 0.5);
  padding: 30px 30px;
  border-radius: 15px;
  height: 72vh;
}

.lign {
  height: 13vh;
  width: 100%;
}

.card {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: rgb(355, 355, 355, 0.5) !important;
}


.flex-item {
  flex-basis: calc(50% - 10px);
  /* Adjust this value for spacing */
  margin: 5px;
  /* Adjust margin for spacing */
}


.capteur-ammoniaque {
  width: 20px;
  height: 30px;
  border-radius: 50%;
}

.point {
  position: absolute;
  opacity: 0.7;
  width: 3px;
  height: 3px;
  border-radius: 50%;
}

.v-dialog {
  width: 1200px !important;
  height: 500px !important;
}

#button-see-curves {
  background-color: white;
  padding: 8px 8px;
  border-radius: 10px;
  border: 3px solid white;
  margin-right: 5px;
  transition: background-color 0.3s ease;
  align-items: center;
}

#button-see-curves:hover {
  background-color: transparent;
}

#button-see-curves p {
  margin-top: -2px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 16px;
}
</style>